<template>
  <v-card class="pa-0 pb-10" tile>
    <v-card-title>
      <h4 class="primary--text">{{ $t('sidebar.chat.archive') }}</h4>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-card outlined flat>
            <v-card-text class="pa-4">
              <v-row dense>
                <v-col cols="12">
                  <label for="search">{{ $t('archive.search') }}</label>
                  <v-text-field v-model="search" id="search" prepend-inner-icon="$magnify" outlined dense single-line hide-details @input="searchDebounce" />
                </v-col>
                <v-col cols="6">
                  <DatePicker
                    v-model="dateFrom"
                    :label="$t('archive.dateFrom')"
                    @change="searchDebounce"
                  />
                </v-col>
                <v-col cols="6">
                  <DatePicker
                    v-model="dateTo"
                    :label="$t('archive.dateTo')"
                    @change="searchDebounce"
                  />
                </v-col>
                <v-col cols="12">
                  <label for="threadType">{{ $t('archive.threadType') }}</label>
                  <v-select
                    id="threadType"
                    :items="conversationThreadTypes"
                    item-value="id"
                    item-text="description"
                    v-model="conversationType"
                    @change="getArchiveConversations(true)"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="archiveConversations.length > 0" outlined flat class="mt-4 thread-list">
            <v-card-text class="pa-0">
              <ThreadOnList
                v-for="thread in archiveConversations"
                :key="thread.conversation.threadId"
                :thread="thread"
                :active="activeThread == thread"
                @click="threadClick(thread)"
                />
              <div class="text-center">
                <v-progress-linear
                  v-if="load"
                  v-intersect="getArchiveConversations"
                  :width="3"
                  :size="25"
                  color="primary"
                  indeterminate
                ></v-progress-linear>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card v-if="activeUser" outlined flat>
            <div class="px-4 pt-4 shadow-bottom">
              <div class="d-flex justify-space-between pb-2">
                <div class="d-flex align-center">
                  <StatusAvatar :userId="activeUser.id" :size="30" class="mr-2" />
                  <div class="d-flex flex-column">
                    <h4 class="primary--text">
                      {{ activeUser.fullName | trimText(25) }}
                    </h4>
                    <span>
                      {{  companyName }}
                    </span>
                  </div>
                </div>
                <v-menu offset-y left bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" depressed icon>
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item link @click="openExportToPDFDialog">
                      <v-list-item-title >
                        {{ $t('chatArchive.exportToPDF') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div v-if="activeConversation && !loadingThread">
                <v-divider />
                <div class="py-2">
                  <span v-if="activeConversation.threads[0].type !== 0">{{ activeConversation.threads[0].name }}</span>
                  <span v-else>{{ $t('common.others')}}</span>
                </div>
              </div>
            </div>
              <ChatThread
                class="chat-thread pl-8 pr-4 pt-2"
                v-if="activeConversation && !loadingThread"
                :conversation="activeConversation"
                :threadId="activeTheadId"
                :isArchive="true"
                />
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="exportToPDFDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      max-width="600"
      persistent
    >
      <v-card class="d-flex flex-column justify-center">
        <v-card-title>
          {{ $t('chatArchive.exportToPDF') }}
        </v-card-title>
        <v-card-text>
          <v-row class="mt-4" no-gutters>
            <v-col cols="12">
              <label>{{ $t('chatArchive.dateFrom') }}</label>
              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exportToPDFVm.dateFrom"
                    :label="$t('chatArchive.dateFrom')"
                    :disabled="loading"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    single-line
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @click:append="fromDateMenu = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exportToPDFVm.dateFrom"
                  :first-day-of-week="1"
                  :max="
                    this.exportToPDFVm.dateTo
                      ? this.exportToPDFVm.dateTo
                      : new Date(Date.now()).toISOString()
                  "
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="mt-4" no-gutters>
            <v-col cols="12">
              <label>{{ $t('chatArchive.dateTo') }}</label>
              <v-menu
                v-model="toDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="exportToPDFVm.dateTo"
                    :label="$t('chatArchive.dateTo')"
                    :disabled="loading"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    single-line
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    @click:append="toDateMenu = true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="exportToPDFVm.dateTo"
                  :first-day-of-week="1"
                  :min="exportToPDFVm.dateFrom"
                  :max="new Date(Date.now()).toISOString()"
                  @input="toDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="mx-3 mb-3 justify-end" no-gutters>
            <v-btn
              class="mr-2"
              outlined
              depressed
              :disabled="loading"
              @click="closeExportToPDFDialog"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="exportConversationToPDF"
            >
              {{ $t('chatArchive.export') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ThreadOnList from './ThreadOnList'
import Services from '@/services'
import ChatThread from '@/chat/Window/ChatThread'
import StatusAvatar from '@/components/StatusAvatar.vue'
import DatePicker from '@/components/forms/DatePicker'
import moment from 'moment'
export default {
  components: {
    ThreadOnList,
    ChatThread,
    StatusAvatar,
    DatePicker
  },
  name: 'Archive',
  data () {
    return {
      threads: [],
      activeThread: null,
      loading: false,
      load: false,
      skipCount: 0,
      activeConversation: null,
      activeTheadId: null,
      dateFrom: null,
      dateTo: null,
      search: null,
      archiveConversations: [],
      conversationType: null,
      loadingThread: false,
      searchDebounceTimeout: null,
      exportToPDFDialog: false,
      exportToPDFVm: {
        dateFrom: '',
        dateTo: ''
      },
      fromDateMenu: false,
      toDateMenu: false
    }
  },
  computed: {
    searchFromParams () {
      return this.$route.params.search
    },
    conversationThreadTypes () {
      const types = [
        {
          id: null,
          description: this.$t('archive.all')
        },
        ...this.$store.getters.enums.conversationThreadType.concat()
      ]

      return types.filter(x => x.id !== 0)
    },
    userId () {
      return this.$store.getters.userCommonData.id
    },
    activeUser () {
      return this.activeThread?.user
    },
    companyName () {
      const company = this.activeUser?.company
      if (!company) {
        return ''
      }
      return `${company.name} - ${company.displayId}`
    },
    runQueryTriggerProperties () {
      return [this.dateFrom, this.dateTo]
    }
  },
  methods: {
    searchDebounce () {
      clearTimeout(this.searchDebounceTimeout)
      this.searchDebounceTimeout = setTimeout(async () => {
        await this.getArchiveConversations(true)
      }, 250)
    },
    async threadClick (thread) {
      this.activeThread = thread
      await this.getConversation(
        thread.conversation.id,
        thread.conversation.threadId
      )
    },
    async getConversation (conversationId, threadId) {
      this.loading = true
      this.loadingThread = true
      try {
        const response = await Services.get('Chat/GetConversation', {
          conversationId,
          threadId,
          showArchived: true
        })
        this.activeConversation = response.data
        this.activeTheadId = threadId
      } finally {
        this.loading = false
        this.loadingThread = false
      }
    },
    async getArchiveConversations (refresh) {
      this.loading = true
      if (refresh === true) {
        this.load = false
        this.archiveConversations = []
        this.activeThread = null
        this.activeConversation = null
        this.activeTheadId = null
        this.skipCount = 0
      }
      if (this.load) {
        this.skipCount = this.archiveConversations.length
      }
      try {
        const { data } = await Services.get('Chat/Searcharchive', {
          dateFrom: moment(this.dateFrom).startOf('day').format(),
          dateTo: moment(this.dateTo).endOf('day').format(),
          skipCount: this.skipCount,
          type: this.conversationType,
          search: this.search
        })

        if (data.results.length > 0) {
          data.results.forEach((resultItem) => {
            if (
              !this.archiveConversations.find(
                (el) =>
                  el.conversation.threadId === resultItem.conversation.threadId
              )
            ) {
              this.archiveConversations.push(resultItem)
            }
          })
          this.load = true
        } else {
          this.load = false
        }
      } finally {
        this.loading = false
      }
    },
    async exportConversationToPDF () {
      this.loading = true
      try {
        let dateTo = new Date(this.exportToPDFVm.dateTo)
        dateTo.setDate(dateTo.getDate() + 1)
        dateTo.setMilliseconds(dateTo.getMilliseconds() - 1)
        let dateFrom = this.exportToPDFVm.dateFrom
        if (!dateFrom) {
          dateFrom = null
        }
        if (!dateTo) {
          dateTo = null
        }
        await Services.post('/Chat/ExportToPDF', {
          conversationId: this.activeThread.conversation.id,
          threadId: this.activeThread.conversation.threadId,
          dateFrom: dateFrom,
          dateTo: dateTo,
          exportArchived: true
        })
      } catch (e) {
      } finally {
        this.closeExportToPDFDialog()
        this.loading = false
      }
    },
    openExportToPDFDialog () {
      this.exportToPDFDialog = true
    },
    closeExportToPDFDialog () {
      this.exportToPDFDialog = false
      this.exportToPDFVm = {
        dateFrom: '',
        dateTo: ''
      }
    }
  },
  watch: {
    runQueryTriggerProperties () {
      this.getArchiveConversations(true)
    }
  },
  async mounted () {
    await this.getArchiveConversations(true)
    if (this.searchFromParams) {
      this.search = this.searchFromParams
      this.searchDebounce()
    }
  },
  destroyed () {
    clearTimeout(this.searchDebounceTimeout)
  }
}
</script>
<style scoped>
.chat-thread {
  height: 475px;
  max-height: 475px;
  overflow-y: auto;
  overflow-x: hidden;
}

.thread-list {
  height: 350px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
